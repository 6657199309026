<template>
    <div class="body_bgi full bgs_full relative" ref="home_box">
      <div class="full jian_bian_bgc">
        <!-- header -->
        <div class="header_bgi2 w_full h_100 bgs_full ph_20 border_box relative ">
          <div class="fw_bold fs_26 text_right ls_10 absolute left_center top_20 color_fff font_zh cursor" @click="goToPage('/')">
            向北开放重要桥头堡大数据平台
          </div> 

          <!-- search -->
          <div class="w_full h_100 flex_row_between flex_center">
            <div class="w_30_p flex_row_start flex_center">
              <div class="w_300">
                <el-input 
                  v-model="hsCode" 
                  size="small"
                  clearable placeholder="请输入HS编码" 
                  @keyup.enter.native="searchHs6()" @clear="clearHs6Value"
                >
                  <el-button slot="append" icon="el-icon-search" @click="searchHs6()"></el-button>
                </el-input>
              </div>
            </div>
          </div>
        </div>

        <!-- body -->
        <div class="w_full-60 h_full-130 m_0_auto border_radius_10 bgc_fafafac2 p_10">
          <div class="full relative z_1" v-if="productInfo.description">
            <div class="w_full pl_10 fs_30">商品描述： {{ productInfo.description }}</div>

            <div class="w_full h_full-50 overflow_y_auto mt_10 border_1_s_5b9ff7" v-if="produstJianGuanInfo['商品名称']">
              <!-- 商品监管信息 -->
              <div class="w_full h_55 lh_55 flex_row_between fs_30" v-for="(value, key) in produstJianGuanInfo" :key="key">
                <div class="flex_1 flex_row_end border_1_s_5b9ff7 pr_10">{{ key }}</div>
                <div class="flex_2 flex_row_start border_1_s_5b9ff7 pl_10 relative" :class="{ color_red: value == '不能入区' }">
                  {{ value }}

                  <div class="w_40 h_35 lh_35 absolute left_100" v-show="key == '海关监管条件要求'">
                    <el-popover
                      placement="right"
                      width="600"
                      trigger="hover"
                    >
                      <h5>2024年<span class="color_ce1126">中国</span>监管条件代码表</h5>
                      <el-table :data="jianGuanData" height="600">
                        <el-table-column width="60" property="code" label="代码"></el-table-column>
                        <el-table-column property="name" label="名称"></el-table-column>
                      </el-table>

                      <div class="w_25 h_25 lh_25 bgc_fff border_1_s_ccc border_radius_5 text_center cursor absolute_center" slot="reference">
                        ?
                      </div>
                    </el-popover>
                  </div>

                  <div class="w_40 h_35 lh_35 absolute left_100" v-show="key == '检验检疫要求'">
                    <el-popover
                      placement="right"
                      width="600"
                      trigger="hover"
                    >
                      <h5>2024年<span class="color_ce1126">中国</span>海关检验检疫代码表</h5>
                      <el-table :data="jianYiData" height="600">
                        <el-table-column width="60" property="code" label="代码"></el-table-column>
                        <el-table-column property="name" label="名称"></el-table-column>
                      </el-table>

                      <div class="w_25 h_25 lh_25 bgc_fff border_1_s_ccc border_radius_5 text_center cursor absolute_center" slot="reference">
                        ?
                      </div>
                    </el-popover>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="full relative" v-else>
            <div class="text_1 absolute_center">暂无该商品的监管信息</div> 
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  import AmapWeather from '@/components/weather/AmapWeather.vue';

  import defMixin from '@/mixins/def';
  import componentsMixin from '@/mixins/components';

  import { Message } from 'element-ui'

  import { 
    getProductInfo,
    getJianGuanInfo,
  } from '@/api/base/index.js'

  export default {
    name: 'Home',
    components: {
      AmapWeather,
    },
    mixins: [defMixin, componentsMixin],
    data() {
      return {
        hsCode: '0101210010',

        productInfo: {},
        produstJianGuanInfo: {},

        jianGuanData: [
          { code: '1', name: '进口许可证' },
          { code: '2', name: '两用物项和技术进口许可证' },
          { code: '3', name: '两用物项和技术出口许可证' },
          { code: '4', name: '出口许可证' },
          { code: '6', name: '旧机电产品禁止进口' },
          { code: '7', name: '自动进口许可证' },
          { code: '8', name: '禁止出口商品' },
          { code: '9', name: '禁止进口商品' },
          { code: 'A', name: '入境货物通关单' },
          { code: 'B', name: '出境货物通关单' },
          { code: 'D', name: '出/入境货物通关单(毛坯钻石用' },
          { code: 'E', name: '濒危物种允许出口证明书' },
          { code: 'F', name: '濒危物种允许进口证明书' },
          { code: 'G', name: '两用物项和技术出口许可证(定向)' },
          { code: 'I', name: '精神药物进(出)口准许证' },
          { code: 'J', name: '黄金及其制品进出口准许证或批件' },
          { code: 'K', name: '民用爆炸物品进出口审批单' },
          { code: 'L', name: '药品进出口准许证' },
          { code: 'M', name: '密码产品和设备进口许可证' },
          { code: 'O', name: '自动进口许可证(新旧机电产品)' },
          { code: 'P', name: '固体废物进口许可证' },
          { code: 'Q', name: '进口药品通关单' },
          { code: 'R', name: '进口兽药通关单' },
          { code: 'S', name: '进出口农药登记证明' },
          { code: 't', name: '关税配额证明' },
          { code: 'U', name: '合法捕捞产品通关证明' },
          { code: 'v', name: '自动进口许可证(加工贸易)' },
          { code: 'W', name: '麻醉药品进出口准许证' },
          { code: 'x', name: '出口许可证(加工贸易)' },
          { code: 'y', name: '出口许可证(边境小额贸易)' },
          { code: 'Z', name: '音像制品进口批准单或节目提取单' },
          { code: 'f', name: '赴境外加工光盘进口备案证明,音像制品(成品)进口批准单' },
          { code: 'V', name: '人类遗传资源材料出口出境证明' },
          { code: 'b', name: '进口广告电影电视节目带(片)提取单' },
          { code: 'k', name: '民用爆炸物品进出口审批单' },
          { code: 'u', name: '有矿物学研究价值的钟乳石出口和出境证明' },
          { code: 'X', name: '有毒化学品环境管理放行通知单' }
        ],

        jianYiData: [
          { code: 'P', name: '进境动植物、动植物产品检疫' },
          { code: 'Q', name: '出境动植物、动植物产品检疫' },
          { code: 'V', name: '代表入境卫生检疫' },
          { code: 'W', name: '代表出境卫生检疫' },
          { code: 'M', name: '进口商品检验' },
          { code: 'S', name: '出口食品卫生监督检验' },
          { code: 'R', name: '进口食品卫生监督检验' },
          { code: 'N', name: '出口商品检验' },
          { code: 'L', name: '民用商品入境验证' }
        ]
      }
    },
    computed: {

    },
    created() {

    },
    async mounted() {
      await this.searchHs6()
    },
    destroyed() {

    },
    methods: {
      async searchHs6() {
        this.isShowChart = true
        this.produstJianGuanInfo = {}

        await this.getProductInfo(this.hsCode)

        await this.getJianGuanInfo(this.hsCode)
      },

      // 获取 商品 信息
      async getProductInfo(hsCode) {
        this.productInfo = {
          description: ''
        }
        let res = await getProductInfo(hsCode)
        try {
          if (res.code == 1000) {
            if (res.data && res.data.description) {
              this.productInfo = res.data
            } else {
              Message({
                message: '暂无该HS编码的商品信息。',
                type: 'warning',
                duration: 1 * 1000
              })
            }
          }
        } catch(err) {
          console.log('获取商品信息失败', err);
        }
      },

      // 获取 监管信息 
      async getJianGuanInfo(hsCode) {
        this.produstJianGuanInfo = {}
        let res = await getJianGuanInfo(hsCode)
        try {
          if (res.code == 1000) {
            this.produstJianGuanInfo = res.data
          }
        } catch(err) {
          console.log('获取商品监管信息失败', err);
        }
      },

      
      clearHs6Value() {
        this.initView()
      },

      initView() {
        this.hsCode = ''
        this.productInfo = {}
        this.produstJianGuanInfo = {}
      },

      // 跳转页面
      goToPage(path) {
        this.$router.push(path)
      }
    }
  }
</script>

