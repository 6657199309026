<template>
  <div>
    <span class="fs_18 color_fff text_1 font_zh font_number x">{{ weather }} </span>
  </div>
</template>

<script>
import { getAmapWeather } from '@/api/weather-api.js';

export default {
  name: "AmapTianQi",
  components: {},
  data() {
    return {
      weather: ''
    };
  },
  computed: {
    
  },
  watch: {
    
  },
  mounted() {
    this.getWeather()
  },
  methods: {
     // 获取牧户 List
     async getWeather() {
      let res = await getAmapWeather();
      // console.log('获取高德地图天气 : ', res);
      this.weather = `天气: ${res.lives[0].weather},  气温: ${res.lives[0].humidity- 35} ℃` 
    },
  }
};
</script>

<style scoped lang="less">
  .text { color: #fff; font-size: 22px; }
</style>
